import { useState } from "react";
import ProjectCards from "./ProjectCards";
const Projects = () => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="Projects" id="Projects">
      <h2>{"> Projects"}</h2>
      <blockquote>"Talk is cheap. Show me the code." - Linus Torvalds</blockquote>

      <div className="container">
        <ProjectCards
          title="Pixelcide"
          description="Online co-op game using standard 54 card deck where up to 4 players go against a castle deck consisting of evil King, Queen and Jack and they need to defeat this deck together to win the game."
          technology={["React", "Framer motion", "Express", "PostgreSQL", "Socket.io"]}
          demoLink="https://pixelcide.netlify.app/"
          sourceLink="https://github.com/GSingh1994/pixelcide"
        />

        <ProjectCards
          title="Resume maker"
          description="Single page React app made while learning react hooks. User can easily create, edit and download resume in pdf format. User can also preview resume while editing because everything is rendered in real-time. "
          technology={["React", "SASS", "React-Pdf"]}
          demoLink="https://gsingh1994.github.io/cv-project/"
          sourceLink="https://github.com/GSingh1994/cv-project"
        />

        <ProjectCards
          title="Interview Scheduler"
          description="Interview Scheduler is a single page app that simulates a web portal that allows students to view and book appointments with instructors. It was made to learn end-to-end testing"
          technology={["React", "Axios", "Storybook", "Cypress"]}
          demoLink="https://github.com/GSingh1994/scheduler"
          sourceLink="https://github.com/GSingh1994/scheduler"
        />

        <ProjectCards
          title="Tech Docs"
          description="PWA made to save my programming notes and some useful web-dev tools that I refer on regular basis. When I start learning code, I couldn't able to find any minimal web-app for this so, I decided to make my own."
          technology={["HTML", "Less", "javaScript", "Prism.js"]}
          demoLink="https://gsingh1994.github.io/my_Documentation/"
          sourceLink="https://github.com/GSingh1994/my_Documentation"
        />

        <ProjectCards
          title="gagansingh.dev"
          description={
            <div>
              It's the site you are currently visiting. It's an updated version of my{" "}
              <a href="https://old-folioport.netlify.app/" target="_blank" rel="noreferrer">
                old dev portfolio
              </a>
              . Everything is responsive and the logo-animation was made in Figma. This website was made after learning Figma and react animations.
            </div>
          }
          technology={["SASS", "React", "Figma"]}
          demoLink="https://gagansingh.dev"
          sourceLink="https://github.com/GSingh1994/portfolioV2"
        />

        <ProjectCards
          title="Rock-Paper Scissors"
          description="simple implementation of classic game where you play against the computer. User can enter the number of rounds he/she want to play. The Computer moves are randomly generated by js."
          technology={["HTML", "CSS", "javaScript"]}
          demoLink="https://gsingh1994.github.io/RPS_Game/"
          sourceLink="https://github.com/GSingh1994/RPS_Game"
        />

        <div className={showMore ? "showMore" : "showLess"}>
          <ProjectCards
            title="Tweeter"
            description=""
            technology={["HTML", "Express", "SASS", "javaScript"]}
            demoLink="https://tweeter-singh.herokuapp.com/"
            sourceLink="https://github.com/GSingh1994/Tweeter"
          />
        </div>

        <div className={showMore ? "showMore" : "showLess"}>
          <ProjectCards
            title="PokeCards"
            description=""
            technology={["React", "Poke API", "SASS"]}
            demoLink="https://gsingh1994.github.io/poke-memo/"
            sourceLink="https://github.com/GSingh1994/poke-memo"
          />
        </div>
        <div className={showMore ? "showMore" : "showLess"}>
          <ProjectCards
            title="Shoppies"
            description=""
            technology={["React", "Material Ui", "OMDB API"]}
            demoLink="https://gsingh1994.github.io/shopifyChallenge/"
            sourceLink="https://github.com/GSingh1994/shopifyChallenge"
          />
        </div>

        <div className={showMore ? "showMore" : "showLess"}>
          <ProjectCards
            title="To-Do List"
            description=""
            technology={["React", "SASS", "Motion-framer"]}
            demoLink="https://gsingh1994.github.io/react-crud/"
            sourceLink="https://github.com/GSingh1994/react-crud"
          />
        </div>
        <div className={showMore ? "showMore" : "showLess"}>
          <ProjectCards
            title="Weather Widget"
            description=""
            technology={["React", "openWeather API"]}
            demoLink="https://weather999.netlify.app/"
            sourceLink="https://github.com/GSingh1994/weather-app"
          />
        </div>
        <div className={showMore ? "showMore" : "showLess"}>
          <ProjectCards
            title="Restaurant-page"
            // description="This is a single-page application,  created to learn npm, Less and Webpack. This project has also expanded my knowledge of ES6 module-based programming and dynamic DOM manipulation."
            description=""
            technology={["HTML", "Less", "javaScript", "Webpack"]}
            demoLink="https://gsingh1994.github.io/restaurant-page/"
            sourceLink="https://github.com/GSingh1994/restaurant-page"
          />
        </div>

        <div className={showMore ? "showMore" : "showLess"}>
          <ProjectCards
            title="Tic Tac Toe"
            description=""
            technology={["HTML", "CSS", "javaScript"]}
            demoLink="https://gsingh1994.github.io/TicTacToe_game/"
            sourceLink="https://github.com/GSingh1994/TicTacToe_game"
          />
        </div>
        <div className={showMore ? "showMore" : "showLess"}>
          <ProjectCards
            title="Calculator"
            description=""
            technology={["HTML", "CSS", "javaScript"]}
            demoLink="https://gsingh1994.github.io/calculator/"
            sourceLink="https://github.com/GSingh1994/calculator"
          />
        </div>
        <div className={showMore ? "showMore" : "showLess"}>
          <ProjectCards
            title="Etch A Sketch"
            description=""
            technology={["HTML", "CSS", "javaScript"]}
            demoLink="https://gsingh1994.github.io/Etch_A_Sketch/"
            sourceLink="https://github.com/GSingh1994/Etch_A_Sketch"
          />
        </div>
      </div>

      <button onClick={() => setShowMore(!showMore)} className="moreBtn">
        {showMore ? "See Less" : "See More"}
      </button>
    </div>
  );
};

export default Projects;
